// import ReactGA from 'react-ga';
import Cookies from 'js-cookie';

const cookieOptions = {
  cookieName: 'gdpr-analytics-enabled',
  expires: 365,
};

export const isUserGACookieSet = () => {
  const { cookieName } = cookieOptions
  const value = Cookies.get(cookieName)
  return value === "true" || value === "false"
};

export const gaOptOut = () => {
  window.gaOptout && window.gaOptout()
};

export const handleUserDidDeclineTrackingCookies = (location) => {
  const { cookieName, ...extraCookieOptions } = cookieOptions
  Cookies.set(cookieName, false, { ...extraCookieOptions })
};

export const handleUserDidAcceptTrackingCookies = (location) => {
  // Note: Previous hits in session of user were not submitted to Google Analytics.
  // We will at least sent the hit of the page, where the user accepts the
  // tracking cookies.
  const { cookieName, ...extraCookieOptions } = cookieOptions
  Cookies.set(cookieName, true, { ...extraCookieOptions })

  window.trackGoogleAnalytics && window.trackGoogleAnalytics()
};

export const trackGoogleAnalyticsEvent = (category, params) => {
  window.trackGoogleAnalyticsEvent && window.trackGoogleAnalyticsEvent(category, params)
};
