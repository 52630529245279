import React from 'react';
import { Link } from 'gatsby';
import classNames from 'classnames';
import useWindowScrolledPastYValue from '../hooks/useWindowScrolledPastYValue';
import './Header.scss';

function Header({ className }) {

    const isScrolled = useWindowScrolledPastYValue(0);

    const onClickBlur = () => {
        document.activeElement.blur();
    }

    return (
        <header className={classNames(
            "header",
            className,
            ...(isScrolled ? ["scrolled"] : []),
        )}>
            <div className="content-wrapper">
                {/* <div className="nav-group">
              <Link to="/impressum">Impressum</Link>
              <Link to="/datenschutzerklaerung">Datenschutz</Link>
            </div> */}
                <div className="name"><Link to="/" onClick={onClickBlur}>Kishor Rana</Link></div>
                <div className="greedy" />
                <div className="nav-group">
                    <Link activeClassName="active" to="/" onClick={onClickBlur}>Home</Link>
                    <Link className="portfolio" activeClassName="active" to="/portfolio" partiallyActive onClick={onClickBlur}>Portfolio</Link>
                    <Link className="contact" activeClassName="active" to="/kontakt" onClick={onClickBlur}>Kontakt</Link>
                </div>
            </div>
        </header>
    )
}

export default Header;
