import React, { useState, useEffect } from 'react';
import classNames from 'classnames';
import './CookieBanner.scss';
import { Link } from 'gatsby';
import {
    handleUserDidDeclineTrackingCookies,
    handleUserDidAcceptTrackingCookies,
    isUserGACookieSet
} from '../helper/GATrackerHelper';

function CookieBanner({ location }) {
    const [show, setShow] = useState(false);
    const isOnLandingPage = location.pathname === "/";

    useEffect(() => {
        setShow(!isUserGACookieSet())
    }, []);

    const handleDismiss = () => {
        setShow(false);
        handleUserDidDeclineTrackingCookies();
    };

    const handleAccept = () => {
        setShow(false);
        handleUserDidAcceptTrackingCookies(location);
    };

    return show === true ? (
        <div key={location.pathname} className={classNames("cookie-banner shadow", ...(isOnLandingPage ? ["landing-page"] : []))}>
            <p>
                <span className="float-left-desktop">Diese Webseite nutzt Cookies. </span>
                Cookies helfen mir den Traffic auf dieser Webseite auszuwerten und Ihnen relevante Dienstleistungen anzubieten.
                Mehr über den Einsatz von Cookies auf dieser Webseite erfahren Sie in der <Link to="/datenschutzerklaerung">Datenschutzerklärung</Link>.
            </p>
            <div className="action-bar">
                <button className="secondary" onClick={handleDismiss}>Ablehnen</button>
                <button className="primary" onClick={handleAccept}>Akzeptieren</button>
            </div>
        </div>
    ) : <React.Fragment />;
}

export default CookieBanner;
