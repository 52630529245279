import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import './Layout.scss';
import Header from './Header';
import Footer from './Footer';
import CookieBanner from './CookieBanner';
import SetDocumentBackgroundColor from '../helper/SetDocumentBackgroundColor';

const Layout = ({ location, mainElementClassname, headerClassname, children }) => {
    return (
        <div className='layout'>
            <SetDocumentBackgroundColor location={location} />
            <CookieBanner location={location} />
            <Header className={headerClassname} />
            <main className={classNames(mainElementClassname)} role="main">{children}</main>
            <footer>
                <Footer />
            </footer>
        </div>
    )
}

Layout.propTypes = {
    mainElementClassname: PropTypes.string,
    headerClassname: PropTypes.string,
}

export default Layout
