import { useState, useEffect } from 'react';

/// Returns `true`, if the window's scroll value is higher than the specified value, or else `false`.
function useWindowScrolledPastYValue(yTarget) {
    const [scrolledPastByYTarget, setScrolledPastByYTarget] = useState(false);

    function logit() {
        setScrolledPastByYTarget(window.pageYOffset > yTarget);
    }

    useEffect(() => {
        window.addEventListener("scroll", logit);
        // Remove listener (like componentWillUnmount)
        return () => {
            window.removeEventListener("scroll", logit);
        };
    });

    return scrolledPastByYTarget;
}

export default useWindowScrolledPastYValue;
