import { useEffect } from "react";

/**
    Handle document-background-color for following two cases:
    * on landing-page ("/"): `html`-background-color shall be the primary-(tint-)color
    * on all other pages: `html`-backround-color shall be white…
*/
function SetDocumentBackgroundColor({ location }) {
    const { pathname } = location;

    useEffect(() => {
        const isOnLandingPage = pathname === "/";

        if (!isOnLandingPage) {
            document.getElementsByTagName("html")[0].style
                .setProperty("background-color", "white");
        } else {
            document.getElementsByTagName("html")[0].style
                .removeProperty("background-color");
        }
    }, [pathname]);

    return null;
}

export default SetDocumentBackgroundColor;
