import React from "react"
import { Link } from "gatsby"

const Footer = () => {
    return (
        <div className="footer">
            <div className="greedy" />
            <div className="nav-group">
                <Link to="/impressum">Impressum</Link>
                <Link to="/datenschutzerklaerung">Datenschutz</Link>
            </div>
        </div>
    )
}

export default Footer
